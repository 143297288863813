<script>
export default {
  name: 'AdClientsCodeur'
}
</script>

<template>
  <div class="col-xl-10 card-footer-cta ml-auto mr-auto">
    <p class="font-italic">Ce calculateur vous est offert par</p>
    <img :src="require('../images/codeur-logo.svg')" alt="Logo de Codeur.com" />
    <p>
      Codeur.com est une plateforme de mise en relation permettant aux entreprises de trouver un travailleur indépendant.
    </p>
    <a
      class="btn btn-codeur"
      href="https://www.codeur.com/projects/new?utm_source=taux%20oraire&utm_medium=cpc&utm_campaign=result"
    >
      Trouver un freelance
    </a>
  </div>
</template>
