<script>
export default {
  name: 'AdFreelancesCodeur'
}
</script>

<template>
  <div class="col-xl-10 card-footer-cta ml-auto mr-auto">
    <p class="font-italic">Ce calculateur vous est offert par</p>
    <img :src="require('../images/codeur-logo.svg')" alt="Logo de Codeur.com" />
    <p>Développez votre clientèle avec Codeur.com, le site des freelances numéro 1 en France. Rejoignez la communauté !</p>
    <a
      class="btn btn-codeur"
      href="https://www.codeur.com/users/sign_up?utm_source=taux%20oraire&utm_medium=cpc&utm_campaign=result"
    >
      Je m'inscris gratuitement
    </a>
  </div>
</template>
