<script>
export default {
  name: 'AdFreelancesRedacteur'
}
</script>

<template>
  <div class="col-xl-10 card-footer-cta ml-auto mr-auto">
    <p class="font-italic">Ce calculateur vous est offert par</p>
    <img :src="require('../images/redacteur-logo.png')" alt="Logo de Redacteur.com" />
    <p>Utilisez gratuitement notre service Redacteur.com. Nous nous occupons de vous trouver de nouveaux clients.</p>
    <a
      class="btn btn-redacteur"
      href="https://www.redacteur.com/identities/sign_up?utm_source=taux%20oraire&utm_medium=cpc&utm_campaign=result"
    >
      Je m'inscris gratuitement
    </a>
  </div>
</template>
