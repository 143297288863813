<script>
import DropdownPeriod from './components/DropdownPeriod.vue'
import AdClientsCodeur from './components/AdClientsCodeur.vue'
import AdFreelancesCodeur from './components/AdFreelancesCodeur.vue'
import AdFreelancesGraphiste from './components/AdFreelancesGraphiste.vue'
import AdFreelancesRedacteur from './components/AdFreelancesRedacteur.vue'

export default {
  name: 'Home',
  components: {
    DropdownPeriod,
    AdClientsCodeur,
    AdFreelancesCodeur,
    AdFreelancesGraphiste,
    AdFreelancesRedacteur
  },
  data() {
    return {
      desiredSalary: 2500,
      desiredSalaryPeriod: 'month',
      expenditure: 200,
      expenditurePeriod: 'month',
      legalStatus: 'freelance',
      jobTitle: '',
      workHours: 40,
      workHoursPeriod: 'week',
      vacation: 1.225
    }
  },
  computed: {
    monthlySalary() {
      return this.desiredSalaryPeriod === 'year' ? this.desiredSalary / 12 : this.desiredSalary
    },
    monthlyExpenditure() {
      return this.expenditurePeriod === 'year' ? this.expenditure / 12 : this.expenditure
    },
    monthlyWorkHours() {
      return this.workHoursPeriod === 'week' ? this.workHours * 4.34524 : this.workHours
    },
    salary() {
      const ratio = this.legalStatus === 'freelance' ? 1.2 : 1.5
      return (this.monthlySalary + this.monthlyExpenditure) * ratio
    },
    workHoursMinusVacation() {
      return this.vacation > 0 ? this.monthlyWorkHours / this.vacation : this.monthlyWorkHours
    },
    hourlyRate() {
      return (this.salary / this.workHoursMinusVacation).toFixed(2)
    }
  },
  methods: {
    numberToEuros(number) {
      return number.toString().replace('.', ',') + ' €'
    }
  }
}
</script>

<template>
  <div class="card home">
    <div class="card-body">
      <div class="row">
        <form class="text-center w-100" @submit.prevent>
          <div class="form-group">
            <label for="desired-salary">Entrez le salaire souhaité</label>
            <div class="input-group col-sm-9 col-md-7 col-xl-6 ml-auto mr-auto">
              <input
                id="desired-salary"
                v-model.number="desiredSalary"
                name="desired-salary"
                class="form-control text-right"
                min="0"
                type="number"
              />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
              <DropdownPeriod :choices="['month', 'year']" :default="'month'" :select.sync="desiredSalaryPeriod" />
            </div>
          </div>

          <div class="form-group">
            <label for="expenditure">Entrez vos charges (hors impôts)</label>
            <div class="input-group col-sm-9 col-md-7 col-xl-6 ml-auto mr-auto">
              <input
                id="expenditure"
                v-model.number="expenditure"
                name="expenditure"
                class="form-control text-right"
                min="0"
                type="number"
              />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
              <DropdownPeriod :choices="['month', 'year']" :default="'month'" :select.sync="expenditurePeriod" />
            </div>
          </div>

          <div class="form-group">
            <label for="legal-status">Quel est votre statut juridique ?</label>
            <div class="col-sm-8 col-md-6 col-xl-5 ml-auto mr-auto text-left">
              <label class="custom-control custom-radio d-block">
                <input
                  v-model="legalStatus"
                  name="legal-status"
                  class="custom-control-input"
                  value="freelance"
                  type="radio"
                />
                <span class="custom-control-label"></span>
                Auto-entrepreneur
              </label>
              <label class="custom-control custom-radio d-block">
                <input
                  v-model="legalStatus"
                  name="legal-status"
                  class="custom-control-input"
                  value="contractor"
                  type="radio"
                />
                <span class="custom-control-label"></span>
                Portage salarial
              </label>
              <label class="custom-control custom-radio d-block">
                <input
                  v-model="legalStatus"
                  name="legal-status"
                  class="custom-control-input"
                  value="individual"
                  type="radio"
                />
                <span class="custom-control-label"></span>
                Entreprise individuelle
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="jobTitle">Quelle est votre profession ?</label>
            <div class="col-sm-9 col-md-7 col-xl-6 ml-auto mr-auto">
              <select id="jobTitle" v-model="jobTitle" name="jobTitle" class="custom-select w-100">
                <option value>Choisissez un secteur professionnel</option>
                <option value="1">Agriculture</option>
                <option value="2">Architecture</option>
                <option value="3">Artisanat d'art</option>
                <option value="4">Arts du spectacle</option>
                <option value="5">Audiovisuel, graphisme, design</option>
                <option value="6">Audit - Conseil</option>
                <option value="7">Automobile</option>
                <option value="8">Banque - Assurances</option>
                <option value="9">Bâtiment et travaux publics (BTP)</option>
                <option value="10">Bien-être</option>
                <option value="11">Commerce et distribution</option>
                <option value="12">Comptabilité, gestion, ressources humaines</option>
                <option value="13">Construction aéronautique, ferroviaire et navale</option>
                <option value="14">Culture - Patrimoine</option>
                <option value="15">Défense</option>
                <option value="16">Droit et justice</option>
                <option value="17">Rédaction, correction, traduction</option>
                <option value="18">Electronique</option>
                <option value="19">Energie</option>
                <option value="20">Enseignement</option>
                <option value="21">Environnement</option>
                <option value="22">Filière bois</option>
                <option value="23">Fonction publique</option>
                <option value="24">Hôtellerie - Restauration</option>
                <option value="25">Industrie alimentaire</option>
                <option value="26">Industrie chimique</option>
                <option value="27">Industrie pharmaceutique</option>
                <option value="28">Informatique, internet et télécoms</option>
                <option value="29">Logistique et transport</option>
                <option value="30">Maintenance, entretien</option>
                <option value="31">Marketing, publicité</option>
                <option value="32">Mécanique</option>
                <option value="33">Mode et industrie textile</option>
                <option value="34">Recherche</option>
                <option value="35">Santé</option>
                <option value="36">Sécurité</option>
                <option value="37">Social</option>
                <option value="38">Sport</option>
                <option value="39">Tourisme</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="workHours">Entrez le nombre d'heures facturées</label>
            <div class="input-group col-sm-9 col-md-7 col-xl-6 ml-auto mr-auto">
              <input
                id="workHours"
                v-model.number="workHours"
                name="workHours"
                class="form-control text-right"
                min="0"
                type="number"
              />
              <div class="input-group-append">
                <span class="input-group-text">H</span>
              </div>
              <DropdownPeriod :choices="['week', 'month']" :default="'week'" :select.sync="workHoursPeriod" />
            </div>
          </div>

          <div class="form-group">
            <label for="vacation">Nombre de semaines de congés</label>
            <div class="col-sm-9 col-md-7 col-xl-6 ml-auto mr-auto">
              <select id="vacation" v-model.number="vacation" name="vacation" class="custom-select w-100">
                <option value="0">aucune</option>
                <option value="1.075">1 semaine / an</option>
                <option v-for="n in 9" :key="n" :value="1 + 0.075 * (n + 1)">{{ n + 1 }} semaines / an</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card-footer text-center">
      <h2>Votre taux horaire :</h2>
      <div class="well col-sm-9 col-md-7 col-lg-7 col-xl-6 ml-auto mr-auto">{{ numberToEuros(hourlyRate) }} / heure</div>

      <AdFreelancesCodeur v-if="['6', '12', '28', '30', '31'].indexOf(jobTitle) !== -1" key="ad-websites" />
      <AdFreelancesGraphiste v-else-if="jobTitle === '5'" key="ad-websites" />
      <AdFreelancesRedacteur v-else-if="jobTitle === '17'" key="ad-websites" />
      <AdClientsCodeur v-else key="ad-websites" />
    </div>
  </div>
</template>
