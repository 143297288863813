import Vue from 'vue'
import Home from './Home.vue'

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('home') === null) {
    return
  }

  window.homeApp = new Vue({
    el: '#home',
    render: h => h(Home)
  })
})
