<template>
  <div class="input-group-append w-50">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-light btn-block dropdown-toggle"
      data-toggle="dropdown"
      type="button"
    >
      {{ availablePeriods[selectedPeriod] }}
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <a
        v-for="(name, key) in availablePeriods"
        v-show="selectedPeriod != key"
        :key="key"
        class="dropdown-item"
        href="#"
        @click.prevent="select(key)"
      >
        {{ name }}
      </a>
    </div>
  </div>
</template>

<script>
import every from 'lodash/every'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import pick from 'lodash/pick'

const PERIODS = {
  week: 'hebdomadaire',
  month: 'mensuel',
  year: 'annuel'
}

export default {
  name: 'DropdownPeriod',
  props: {
    choices: {
      type: Array,
      required: false,
      default: keys(PERIODS),
      validator(values) {
        const validChoices = keys(PERIODS)
        return every(values, value => {
          return includes(validChoices, value)
        })
      }
    },
    default: {
      type: String,
      required: false,
      default: '',
      validator(value) {
        return includes(keys(PERIODS), value)
      }
    }
  },
  data() {
    return {
      selectedPeriod: this.default
    }
  },
  computed: {
    availablePeriods() {
      return pick(PERIODS, this.choices)
    }
  },
  methods: {
    select(period) {
      this.selectedPeriod = period
      this.$emit('update:select', period)
    }
  }
}
</script>
