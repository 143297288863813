<script>
export default {
  name: 'AdFreelancesGraphiste'
}
</script>

<template>
  <div class="col-xl-10 card-footer-cta ml-auto mr-auto">
    <p class="font-italic">Ce calculateur vous est offert par</p>
    <img :src="require('../images/graphiste-logo.svg')" alt="Logo de Graphiste.com" />
    <p>
      Graphiste.com, la communauté des créatifs. Vous cherchez des missions et des clients ? Inscrivez-vous c'est gratuit !
    </p>
    <a
      class="btn btn-graphiste"
      href="https://graphiste.com/signup/?utm_source=taux%20oraire&utm_medium=cpc&utm_campaign=result"
    >
      Je m'inscris gratuitement
    </a>
  </div>
</template>
